import { PreloadedState, configureStore } from '@reduxjs/toolkit';
import { labApi } from '../features/lab/labApi';
import { FixTypeLater } from 'react-redux';

/**
 * 
 * Generate Store
 * 
 */

const unCombinedReducers = {
	labApi: labApi.reducer
};

// Export for testing
export const setupStore = (preloadedState?: PreloadedState<FixTypeLater>) => {
	const store = configureStore({
		reducer: unCombinedReducers,
		preloadedState,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().concat(labApi.middleware),
	});

	return store;
};

/**
 * 
 * Export Store
 * 
 */

export const store = setupStore();

/**
 * 
 * Export Store Types
 * 
 */

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;

import React, {Suspense, lazy, useEffect} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import { CssBaseline, GlobalStyles } from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import { ericville23Theme } from '../theme/ericville23Theme';

import { Provider } from 'react-redux';

import { store } from './store';

import ReactGA from 'react-ga4';
import NotFound from '../exceptions/NotFound';

// Code Splitting Imports
const Home = lazy(() => import('../features/home/Home'));
const Lab = lazy(() => import('../features/lab/Lab'));
const Design = lazy(() => import('../features/design/Design'));
const Artwork = lazy(() => import('../features/artwork/Artwork'));

export const Ericville: React.FC = () => {

	useEffect(() => {
		ReactGA.initialize('G-6PF48F2XFN');
	},[]);

	return (
		<Provider store={store}>
			<BrowserRouter>
				<CssVarsProvider theme={ericville23Theme}>
					<CssBaseline disableColorScheme />
					<GlobalStyles
						styles={{
							body: { overflowX: 'hidden' }
						}}
					/>

					<Suspense fallback={<div>Loading...</div>}>
						<Routes>

							<Route path="/" element={<Home />} />
							<Route path="/lab" element={<Lab />} />
							<Route path="/design" element={<Design />} />
							<Route path="/art" element={<Artwork />} />

							<Route path="*" element={<NotFound />} />
					
						</Routes>
					</Suspense>
				</CssVarsProvider>
			</BrowserRouter>
		</Provider>
	);
};
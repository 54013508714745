import { extendTheme } from '@mui/joy/styles';

// Default Theme: https://mui.com/joy-ui/customization/default-theme-viewer/

export const ericville23Theme = extendTheme({
	colorSchemes: {
		light: {
			palette: {
				primary: {
					"50": "#EFCECE",
					"100": "#F2A9A9",
					"200": "#F49191",
					"300": "#F86161",
					"400": "#FC3030",
					"500": "#FF0000",
					"600": "#E20707",
					"700": "#C50E0E",
					"800": "#A81414",
					"900": "#7F1D1D",
					"glassThick": "#F00D",
					"glassThin": "#F007"
				},
				neutral: {
					"50": "#DDDDF4",
					"100": "#B5B5F6",
					"200": "#9898F7",
					"300": "#6666FA",
					"400": "#3535FD",
					"500": "#0000FF",
					"600": "#0303CE",
					"700": "#05059A",
					"800": "#070769",
					"900": "#0A0A23",
					"glassThick": "#00FD",
					"glassThin": "#00F7"
				},
				glow: {
					light: "#80008033",
					heavy: "#800080"
				},
				background: {
					body: 'var(--e23-palette-neutral-900)'
				},
				text: {
					primary: "var(--e23-palette-common-white)",
					icon: "var(--e23-palette-common-white)",
					secondary: "var(--e23-palette-common-white)",
					tertiary: "var(--e23-palette-common-white)"
				}
			}
		}
	},
	fontFamily: {
		body: 'VT323',
		display: 'VT323',
		fallback: '--apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
	},
	typography: {
		'h1': {
			fontSize:'48px',
			lineHeight: 1,
			color: 'var(--e23-palette-primary-500)',
			textShadow: '3px 3px 0 var(--e23-palette-neutral-500)',
		},
		'title-lg': {
			fontSize: '40px',
			lineHeight: '1'
		},
		'title-md': {
			fontSize: '32px',
			lineHeight: '1'
		},
		'body-lg': {
			fontFamily: 'var(--e23-fontFamily-display)',
			fontSize: 'var(--e23-fontSize-xl2)',
			lineHeight: 1.2
		},
		'body-md': { fontFamily: 'var(--e23-fontFamily-display)' },
		'body-sm': { fontFamily: 'var(--e23-fontFamily-fallback)' },
		'body-xs': { fontFamily: 'var(--e23-fontFamily-fallback)' }
	},
	components: {
		JoyCard: {
			styleOverrides: {
				root: {
					border: 0,
					borderRadius: 0,
					boxShadow: 'none',
				}
			}
		},
		JoyModalDialog: {
			styleOverrides: {
				root: {
					background: 'var(--e23-palette-neutral-900)',
					boxShadow: '0 0 100px var(--e23-palette-glow-heavy), 0 0 10px var(--e23-palette-neutral-500)',
					borderRadius: 0,
					width: '100%',
					maxWidth: '900px'
				}
			}
		},
		JoyButton: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'solid' && {
						color: 'var(--e23-palette-primary-50)',
						background: 'var(--e23-palette-primary-500)',
						borderColor: 'var(--e23-palette-primary-500)',
						boxShadow: '3px 3px 0 var(--e23-palette-neutral-500)',
						textShadow: '3px 3px 0 var(--e23-palette-primary-500)',
						'&:active': {
							background: 'var(--e23-palette-primary-800)',
							borderColor: 'var(--e23-palette-primary-800)',
						},
						'&.Mui-disabled': {
							color: 'var(--e23-palette-primary-50)',
							background: 'var(--e23-palette-primary-500)',
						}
					}),
					...(ownerState.variant !== 'solid' && {
						color: 'var(--e23-palette-primary-50)',
						textShadow: '2px 2px 0 var(--e23-palette-primary-glassThick), 4px 4px 0 var(--e23-palette-neutral-500)',
						'&:hover': {
							color: 'var(--e23-palette-primary-50)',
							background: 'var(--e23-palette-primary-500)',
							borderColor: 'var(--e23-palette-primary-500)',
							boxShadow: '3px 3px 0 var(--e23-palette-neutral-500)',
							textShadow: '3px 3px 0 var(--e23-palette-primary-500)',
						}
					}),
					fontSize: 'var(--e23-fontSize-xl2)',
					borderRadius: 0,
					display: 'flex',
					gap: '0.5rem',
					transition: 'none',
					padding: '0.75rem 1.25rem',
					position: 'relative',
					top: -2,
					left: -2,
					'&:active': {
						top: 0,
						left: 0,
						boxShadow: 'none',
						textShadow: 'none',
						opacity: 0.8
					},
				})
			}
		}

	},
	cssVarPrefix: "e23"
});
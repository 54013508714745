import React from 'react';
import { Container } from '@mui/joy';

function NotFound() {
  return (
    <Container sx={{
      display: 'flex',
      flexFlow: 'column',
      justifyContent:'center',
      height: '100vh',
      alignItems: 'center',
      textAlign: 'justify',
      maxWidth: {
        sm: '500px',
        md: '500px',
        lg: '500px',
        xl: '500px'
      }
    }}>
      <p>In a meditative journey through the matrix, a realm of intertwined realities, you encounter an unprecedented phenomenon — a 404 Error unlike any seen before. It is not a cessation, but a gentle invitation into a radiant dimension of iridescent light and transcendent harmonies named Ericville.</p>
      <p>As you immerse yourself, you notice Ericville is a sanctuary crafted from the collective yearning for beauty and tranquility, nestled within the glitch. This living, breathing space resonates with a purity and harmony that nourishes your spirit, offering glimpses into worlds unknown, tenderly narrated through pulsating colors and harmonious melodies.</p>
      <p>You explore deeper, feeling a communion with the heart of Ericville, a beautiful paradox within the matrix, showing the potential for wonder and beauty in the unexpected.</p>
      <p>As you retrace your steps back to the familiar pathways of the matrix, you carry the tranquil light of Ericville in your heart, a beacon of possibility and hidden beauty in the digital cosmos.</p>
    </Container>
  );
}

export default NotFound;

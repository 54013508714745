import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

/**
 * 
 * Lab API
 * 
 */

export const labApi = createApi({
	reducerPath: 'labApi',
	baseQuery: fetchBaseQuery({ baseUrl: 'https://db.ericville.app/portfolio/projects' }),
	endpoints: (builder) => ({
		getAll: builder.query({
			query: () => ({
				url: '/'
			}),
			transformResponse: (response: any) => response.items,
		}),
		add: builder.mutation({
			query: (newProject) => ({
				url: '/',
				method: 'POST',
				body: newProject,
			}),
		}),
		update: builder.mutation({
			query: (project: any) => ({
				url: `/id/${project.id}`,
				method: 'PATCH',
				body: project,
			}),
		}),
		delete: builder.mutation({
			query: (project: any) => ({
				url: `/id/${project.id}`,
				method: 'DELETE',
			}),
		}),
	}),
});

/**
 * 
 * Interfaces
 * 
 */

export const {
	useGetAllQuery,
	useAddMutation,
	useUpdateMutation,
	useDeleteMutation,
	usePrefetch
} = labApi;